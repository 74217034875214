<template>

	<tr
		:id="'player-' + player.id"
		class="player-row"
		:class="get_classes"
		ref="row"
	>

		<td class="team-logo" @click="select">
			<div :title="'ID : ' + player.player_id" class="logo">
				<img v-if="this.player.vue.team_logo" :src="require( '@/assets/img/logos/' + player.vue.team_logo )">
			</div>
		</td>

		<td class="player-fullname" @click="select">
			<div :title="'ID : ' + player.player_id">
				{{ player.vue.fullname }} <em v-if="player.ttfl.b2b">B2B</em>
			</div>
		</td>

		<td class="status" @click="select">
			<div :class="'injury ' + player_injury.class" :title="player_injury.details"></div>
		</td>

		<td class="separator"></td>

		<td class="score"><div class="average score">
			<span :style="'opacity:' + player.vue.ttfl_l10_av_color.opacity" :class="'ttfl-score_bg ' + player.vue.ttfl_l10_av_color.class"></span>
			<span class="ttfl-score">{{ player.ttfl.last_10days.avg }}</span>
		</div></td>

		<td class="score"><div class="average score">
			<span :style="'opacity:' + player.vue.ttfl_l30_av_color.opacity" :class="'ttfl-score_bg ' + player.vue.ttfl_l30_av_color.class"></span>
			<span class="ttfl-score">{{ player.ttfl.last_30days.avg }}</span>
		</div></td>

		<td class="score"><div class="average score">
			<span :style="'opacity:' + player.vue.ttfl_av_color.opacity" :class="'ttfl-score_bg ' + player.vue.ttfl_av_color.class"></span>
			<span class="ttfl-score">{{ player.ttfl.avg_season }}</span>
		</div></td>

		<td class="separator"></td>

		<td class="team-logo team_opp-logo">
			
			<div :title="'ID : ' + player.vue.team_opp_id" class="logo">
				<span v-if="player.vue.home_game">VS</span>
				<span v-else>@</span>
				<img :src="require( '@/assets/img/logos/' + player.vue.team_vs_logo )">
			</div>
		</td>

		<td class="games_historical">

			<div class="scores-list">
				<a :href="game_url( game.game_url )" target="_blank" v-for="( game, key ) in player.ttfl.last_3vs.games" v-bind:key="key" class="score">
					<span :style="'opacity:' + game.ttfl.opacity" :class="'ttfl-score_bg ' + game.ttfl.class"></span>
					<span :title="game.date" class="ttfl-score">{{ game.ttfl_score }}</span>
				</a>
			</div>

		</td>

		<td class="score"><div class="average score">
			<span :style="'opacity:' + player.ttfl.index_vs.opacity" :class="'ttfl-score_bg ' + player.ttfl.index_vs.class"></span>
			<span class="ttfl-score">{{ player.ttfl.index_vs.value }}</span>
		</div></td>

		<td class="score"><div class="average score">
			<span :style="'opacity:' + player.ttfl.index_def.opacity" :class="'ttfl-score_bg ' + player.ttfl.index_def.class"></span>
			<span class="ttfl-score">{{ player.ttfl.index_def.value }}</span>
		</div></td>

		<td class="separator"></td>

		<td class="align-center score"><div class="momentum average score">
			<span :style="'opacity:' + player.ttfl.mom.opacity" :class="'ttfl-score_bg ' + player.ttfl.mom.class"></span>
			<span class="ttfl-score">{{ player.ttfl.mom.value }}</span>
		</div></td>

		<td class="align-center score"><div class="b2b average score">
			<span :style="'opacity:' + player.ttfl.index_b2b.opacity" :class="'ttfl-score_bg ' + player.ttfl.index_b2b.class"></span>
			<span class="ttfl-score">{{ player.ttfl.index_b2b.value }}</span>
		</div></td>

		<td class="align-center score"><div class="average score">
			<span :style="'opacity:' + player.ttfl.homeaway.opacity" :class="'ttfl-score_bg ' + player.ttfl.homeaway.class + ' ' + ( player.ttfl.home_game === true ? 'home' : 'away' )"></span>
			<span class="ttfl-score">{{ player.ttfl.homeaway.value }}</span>
		</div></td>

		<td class="score"><div class="stab index score">
			<span :style="'opacity:' + player.ttfl.index_stab.opacity" :class="'ttfl-score_bg ' + player.ttfl.index_stab.class"></span>
			<span class="ttfl-score">{{ player.ttfl.index_stab.value }}</span>
		</div></td>

		<td class="estimation score"><div class="average score">
			<span :style="'opacity:' + player.ttfl.estimation.opacity" :class="'ttfl-score_bg ' + player.ttfl.estimation.class"></span>
			<span class="ttfl-score">{{ player.ttfl.estimation.value }}</span>
		</div></td>

		<td class="align-center score"><div class="index score">
			<span :style="'opacity:' + player.ttfl.index.opacity" :class="'ttfl-score_bg ' + player.ttfl.index.class"></span>
			<span class="ttfl-score">{{ player.ttfl.index.value }}</span>
		</div></td>

		<td class="actions"><div>

			<button class="btn-pick btn-action" v-if="get_user_id() && player.availability === true" @click="pick">
				<font-awesome-icon :icon="[ 'fas', 'user-check' ]" />
			</button>
			<button class="btn-pick btn-action" v-else-if="get_user_id() && player.availability !== true">
				<span>J-{{ player.availability }}</span>
			</button>
			<button class="disabled btn-pick btn-action" v-else>
				<font-awesome-icon :icon="[ 'fas', 'user-slash' ]" />
			</button>

			<button
				@click="player.advanced	= !player.advanced"
				:data-player_id="player.id"
				:class="{
				'btn-advanced'	: true,
				'btn-action'	: true,
				'open'	: player.advanced
			}">
				<font-awesome-icon :icon="[ 'fas', 'minus' ]" class="minus" />
				<font-awesome-icon :icon="[ 'fas', 'plus' ]" class="plus" />
			</button>

		</div></td>

	</tr>

</template>

<script>

	import { library } from '@fortawesome/fontawesome-svg-core'
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

	import { faMinus, faPlus, faUserCheck, faUserSlash } from '@fortawesome/free-solid-svg-icons'
	library.add( faMinus, faPlus, faUserCheck, faUserSlash )

	import mixin_device from '@/mixins/device'
	import mixin_tools from '@/mixins/tools'
	import mixin_player from '@/mixins/player'

	export default {
		
		name	: 'playerRow',

		components	: {
			
			'font-awesome-icon' : FontAwesomeIcon,
			
		},

		mixins	: [ mixin_device, mixin_tools, mixin_player ],

		props	: [
			'player',
			'user',
		],

		data	: function() { return {
		}},

		methods	: {
		},

		watch	: {
		},

		computed	: {

			/**
			 */

			player_store() {
				return this.$store.getters['players/get_player']( this.player.id )
			},

			/**
			 */

			player_injury() {
				return this.player.injury
			},

		},

		mounted	: function() {
		},

	}

</script>

<style lang="scss" scoped>

	@import '@/assets/scss/_base';


	
	/** ---------------------------------------*\
	*---------------------------------------- **/

	tr.player-row {

		&.pick_unavailable {
			opacity: 0.5;
		}

		&.pick_today {
			background: transparentize( $green, 0.8 );
		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	tr.player-row td {

		vertical-align: middle;
		height: auto;
		min-height: 55px;
		padding: 0;
		border-top: 1px solid #dee2e6;
		border-bottom: 1px solid #dee2e6;

		&.score {
			width: 40px;
		}

		& > div {
			position: relative;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			width: 100%;
			height: 100%;
		}

		.score {

			width: 100%;
			height: 40px;
			padding: 0;
			margin: 0 auto;
			line-height: 40px;
			text-align: center;

		}

		&.compare,
		&.team-logo {
			width: 50px;
			text-align: center;
		}

		&.team-logo.team_opp-logo {

			width: 65px;

			& > div {
				width: 65px;
				padding-left: 10px;
			}

			span {
				margin-right: 10px;
				font-size: 0.6em;
				line-height: 1em;
				font-weight: bold;
			}

			img {
				width: 23px;
				height: auto;
			}

		}

		& > span.checkbox-container {
			display: inline-block;
			vertical-align: middle;
			width: auto;
			height: 17px;
		}

		&.player-fullname {

			font-size: 0.8em;
			user-select: none;

			em {
				display: inline-block;
				vertical-align: middle;
				margin-left: 5px;
				font-size: 0.7em;
				line-height: 1em;
				font-weight: bold;
				transform: translateY(-5px);
			}

		}

		&.separator {
			padding: 5px;
			background-color: darken( #FFF, 10% );
		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	tr.player-row td.status {

		padding: 0;
		padding-right: 15px;
		text-align: center;

		div.injury {
			display: inline-block;
			vertical-align: middle;
			width: 15px;
			height: 15px;
			margin: 0;
		}

		div.injury.notready {
			background-color: rgba( 0, 0, 0, 0.2 );
		}

		div.injury.healthy {
			background-color: $green;
		}

		div.injury.out {
			background-color: $red;
		}

		div.injury.gtd {
			background-color: $orange;
		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	tr.player-row td.team-logo > div,
	tr.player-row td.player-fullname > div {
		cursor: pointer;
	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	tr.player-row td.team-logo {

		width: 40px;
		text-align: center;

		& > div {
			width: 100%;
			height: 100%;
			padding: 5px 12.5px;
			margin-left: auto;
			margin-right: auto;
		}

		.logo {
			width: 50px;
			height: 40px;
		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	tr.player-row td.actions {

		width: 80px;

		button svg {
			width: calc( 100% - 15px );
			height: calc( 100% - 15px );
		}

		button.btn-advanced {

			.minus {
				opacity: 0;
				pointer-events: none;
			}

			&.open {

				svg.plus {
					transform: rotate(90deg);
					opacity: 0;
					pointer-events: none;
				}

				svg.minus {
					opacity: 1;
					pointer-events: auto;
				}

			}

		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	tr.player-row.pick_unavailable {

		td.actions button.btn-pick {
			pointer-events: none;
		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	tr.player-row.selected {

		background-color: transparentize( $color: $purple, $amount: 0.6 );

	}

</style>