
import mixins_global from '@/mixins/global'

export default {

	mixins	: [ mixins_global ],

	data : function() { return {

		compare	: {

			without : true,
			mate_id : false,
			mate : false,
			games : [],

		},

	}},

	created	: function () {
	},

	methods	: {

		/**
		 */

		get_user_id() {
			return this.$store.state.user.id
		},

		/**
		 * Pick d'un joueur
		 */

		pick	: function() {

			var _this	= this

			this.loader( 'pick', true )

			this.$store.dispatch( 'user/api_set_pick', {
				
				id	: this.get_user_id(),
				player_id	: this.player.id,
				date	: this.$parent.$parent.$parent.day.format( 'YYYY-MM-DD' ),
				callback	: function() {

					_this.$emit( 'pick_player', _this.player.id )
					_this.loader( 'pick' )

				},

			})

		},

		/**
		 */

		select	: function() {

			this.$emit( 'select_player', this.player.id, !this.player.selected )

		},

		/**
		 * Filtre les coéquipiers sans aucun match
		 */

		get_mates : function() {

			var _this	= this
			var mates	= []

			this.player.team_roster.forEach(function( mate ) {

				var player	= _this.$store.getters['players/get_player']( mate.personId )

				if ( player && player.player_id != _this.player.player_id ) {

					if ( player.ttfl && player.ttfl.games.length > 0 ) mates.push( player )

				}

			})

			return mates

		},

		/**
		 */

		get_mates_options	: function() {

			var mates	= this.get_mates()
			var options	= []

			mates.forEach(function( player ) {

				options.push({
					label	: player.fname.substring( 0, 1 ) + '. ' + player.lname,
					value	: player.player_id,
				})

			})

			return options
			
		},

		/**
		 */

		change_without	: function() {

			this.compare.without	= !this.compare.without
			this.mate_compare()

		},

		/**
		 */

		change_mate	: function( selected ) {

			if ( selected.value == '' ) {
				
				this.compare.mate	= false
				this.compare.games	= []
				return

			}

			this.compare.mate	= this.$store.getters['players/get_player']( selected.value )

			if ( !this.compare.mate ) {
				this.compare.mate	= false
				return false
			}

			this.compare.mate_id	= this.compare.mate.player_id	// Pour activer le watcher
			this.mate_compare()

		},

		/**
		 * Affichage des scores TTFL avec/sans un autre coéquipier
		 */

		mate_compare	: function() {

			if ( !this.compare.mate )	return false
			
			var _this	= this

			this.compare.games	= []

			/**
			 * Parcours les matchs du joueur et retourne (grâce à .filter) si le match a bien été joué par le joueur et le coéquipier sélectionné.
			 * 
			 * Suivant la sélection sans/avec, envoi ou non du match dans le tableau de la vue
			 */

			var tmp_matched_games	= []

			this.player.ttfl.games.forEach( function( game ) {

				/**
				 * Recherche du match et vérification s'il a été joué ou non avec le joueur sélectionné
				 */

				var matched_game	= _this.compare.mate.ttfl.games.find( mate_game => ( game.date == mate_game.date && mate_game.played ) )

				if ( game.played &&  _this.compare.without === false && matched_game ) {
					tmp_matched_games.push( game )
				} else if ( game.played && _this.compare.without === true && !matched_game ) {
					tmp_matched_games.push( game )
				}

			})

			this.compare.games	= tmp_matched_games.slice( 0, 10 )

		},

	},

	computed	: {

		/**
		 * @returns object player row classes
		 */
		
		get_classes() {

			return {
				selected	: this.player.selected,
				pick_unavailable	: ( this.player.availability !== true && !this.player.today_pick ),
				pick_today	: ( this.player.today_pick ),
			}

		},

		/**
		 * Récupération du pseudo de l'utilisateur en cours
		 */

		user_pseudo() {

			if ( this.$store.getters['team/get_user_by_id']( this.$store.state.user.id ) ) {
				return this.$store.getters['team/get_user_by_id']( this.$store.state.user.id ).pseudo
			} else {
				return false
			}
		},

		/**
		 * Récupération des picks du joueur pour l'utilisateur en cours
		 */
		
		user_picks() {

			var _this	= this
			var day_player	= this.$store.getters['players/get_player']( this.player.player_id )
			var picks	= this.$store.state.user.picks.filter( pick => pick.player_id == _this.player.player_id )
			var user_games	= []

			picks.forEach(function( pick ) {

				var game	= day_player.ttfl.games.find( game => game.date == pick.date )

				if ( game ) {
					user_games.push( game )
				}

			})

			return user_games

		},

		/**
		 * Panneau avancé ouvert
		 */

		advanced_open() {
			return this.player.advanced
		},

	},

	watch	: {
	},

	mounted	: function() {

		var _this	= this

		if ( _this.player.upcoming_games && _this.player.upcoming_games.length > 0 ) {

			_this.player.upcoming_games.forEach(function( upcoming ) {

				if ( upcoming.mounted )	return false

				upcoming.date	= _this.$moment( upcoming.date ).format( 'dd DD' )
				upcoming.estimation	= _this.ttfl_score_color( upcoming.estimation )
				upcoming.index_vs	= _this.ttfl_momentum( upcoming.index_vs )
				upcoming.index_def	= _this.ttfl_momentum( upcoming.index_def )
				upcoming.index_homeaway	= _this.ttfl_momentum( upcoming.index_homeaway )
				upcoming.team_opp	= _this.search_team( upcoming.team_opp_id )

				upcoming.last_3vs.games.forEach(function( game ) {
					game.ttfl_score	= _this.ttfl_score_color( game.ttfl_score )
				})

				upcoming.mounted	= true

			})

		}

	},

}